/* Body resets
-------------------------------------------------- */

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-family: "freight-text-pro", Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 17px;
  letter-spacing: .005rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.45;
  overflow-y:scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
@media (min-width: 48em) {
  html {
    font-size: 18px;
  }
}

body {
  padding: 2rem 1.5rem;
  line-height: 1.45;
  color: rgba(0,0,0,0.8);
  background-color: #fff;
}

p {
  margin: 0 0 1rem;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: bold;
  line-height: 1.1;
  color: #333;
  letter-spacing: -.025rem;
}

h1 {
  font-size: 1.75rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.25rem;
}
p + h1,
p + h2,
p + h3,
ul + h1,
ul + h2,
ul + h3,
ol + h1,
ol + h2,
ol + h3 {
  margin-top: 1.5rem;
}

/* Emphasis */
em,
strong {
  padding-left: .1em;
  padding-right: .1em;
}

em {
  color: #666;
}

strong {
  font-weight: bold;
  color: #333;
}

/* Lists */
ul,
ol {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 1.5em;
}
dt {
  font-weight: bold;
}
dd {
  margin-bottom: .5rem;
}

.body li {
  margin-bottom: .25rem;
}
ul ul {
  margin-top: .25rem;
  margin-bottom: .5rem;
}

hr {
  width: 100px;
  height: 1px;
  margin: 2rem auto;
  background-color: #eee;
  border: 0;
}

abbr {
  background-color: #eee;
  display: inline-block;
  padding: 3px;
  font-size: 13px;
  font-weight: bold;
  color: #555;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  border-radius: 3px;
}

code, pre {
  font-family: "JetBrains Mono", Menlo, Monaco, "Courier New", monospace;
}
code {
  padding: .15em .3em;
  font-size: 80%;
  font-style: normal; /* Prevent inheritance from em */
  font-weight: normal; /* Prevent inheritance from strong */
  color: #d44950;
  background-color: #f5f5f5;
  border-radius: .15em;
}
.highlighter-rouge {
   font-size: 75%;
}
@media (max-width: 30em) {
  code {
    word-break: break-word;
  }
}

figure {
   margin: 0;
}

pre {
  display: block;
  margin: 0 0 1rem;
  font-size: .875rem;
  line-height: 1.4;
  white-space: pre;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}
pre code {
  padding: 0;
  color: inherit;
  background-color: transparent;
}

/* Gist via GitHub Pages */
.gist .gist-file {
  font-family: Menlo, Monaco, "Courier New", monospace !important;
}
.gist .markdown-body {
  padding: 15px;
  font-size: 14px !important;
}
.gist .highlight {
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
}
.gist pre {
  padding: 0;
  background-color: transparent;
}
.gist .gist-file .gist-data {
  font-size: 80% !important;
  line-height: 1.4;
}
.gist code {
  padding: 0;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}

blockquote {
  padding: .5rem 1rem;
  margin: .8rem 0;
  color: #999;
  border-left: .25rem solid #e5e5e5;
}
blockquote p:last-child {
  margin-bottom: 0;
}

img {
  display: block;
  margin: 0 0 1rem;
  border-radius: .25rem;
}

/* Tables */
table {
  margin-bottom: 1rem;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
}
td,
th {
  padding: .25rem .5rem;
  border: 1px solid #e5e5e5;
}
tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}

/* Links
-------------------------------------------------- */

a {
  font-weight: inherit;
  line-height: inherit;
  color: #222;
  text-decoration: none;
  -webkit-transition: all .2s ease-in-out;
     -moz-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
}
a:hover {
  color: #000;
}

.link-twitter {
   color: #ccc;
}
.link-twitter:hover {
   color: #00aced !important;
}

/* Scaffolding
-------------------------------------------------- */

.container {
  max-width: 48rem;
  margin: 0 auto;
}

/* Masthead */
.masthead {
  margin-bottom: 0;
  font-size: 1.05rem;
  color: #777;
}
.masthead:after {
  display: block;
  content: "";
  width: 100px;
  height: 1px;
  margin: 3rem auto;
  background-color: #eee;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav li {
  display: inline-block;
}
.nav li + li {
  margin-left: .5rem;
}

@media (min-width: 34em) {
  .masthead {
    padding-top: 1rem;
    text-align: left;
  }
  .masthead-title {
    float: right;
  }
}

@media (max-width: 48em) {
  .masthead-title {
    display: none;
  }
}

.masthead-title .link-twitter {
   margin-left: .5rem;
}

.masthead-tagline {
  color: #ccc;
}

.qna,
.share,
.related,
.footer {
  text-align: center;
}

.qna .link-twitter,
.share .link-twitter{
   vertical-align: -.1em;
}

.description a,
.related a,
.footer a {
  border-bottom: 1px solid #ddd;
}

.description a:hover,
.related a:hover,
.footer a:hover {
  border-bottom-color: #000;
}

.related ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

a[href^="#fn:"],
a[href^="#fnref:"] {
  display: inline-block;
  margin-left: .1rem;
  font-weight: bold;
}

.footnotes {
  margin-top: 2rem;
  font-size: 85%;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

/* Posts
-------------------------------------------------- */

/* List of posts
------------------------- */
.posts {
  margin-bottom: 4rem;
}
.posts li small {
  color: #999;
}
.posts li a:hover {
  color: #000;
  text-decoration: none;
}
/*.posts li a:hover small {
  color: #000;
}*/

/* Single post
------------------------- */
.post {
  position: relative;
  /*margin-bottom: 3rem;*/
}
/*.post:after {
  display: block;
  content: "";
  width: 100px;
  height: 1px;
  margin: 4rem auto;
  background-color: #eee;
}*/
.post img,
.post iframe {
  max-width: 100%;
}
.post img {
  margin-left: auto;
  margin-right: auto;
}
.post iframe {
  margin-bottom: 1rem;
}
.tweet-button {
  margin: .5rem 0 0;
}
.post-date {
  display: block;
  margin: -.5rem 0 .5rem;
  color: #ccc;
}

/*.page {
  padding-bottom: 2rem;
}*/
.page-title {
  margin-bottom: 2rem;
}
/*.page-body > p:first-child {
  font-size: 1.25rem;
}*/

/* Links in paragraphs get extra love */
.archive a,
.post-body a,
.page-body a {
  border-bottom: 1px solid #ddd;
}
.archive a:hover,
.post-body a:hover,
.page-body a:hover {
  border-bottom-color: #000;
}

/* Twitter tweet embeds */
iframe.twitter-tweet.twitter-tweet-rendered {
  margin: 1rem auto !important;
}

/* Pagination
-------------------------------------------------- */

.pagination {
  color: #ccc;
  text-align: center;
}
.pagination a {
  display: inline-block;
  padding: .5rem 1rem;
  margin: 0 .5rem;
}
.pagination a {
  border-radius: .25rem;
  border: 1px solid #fff;
}
.pagination a:hover {
  text-decoration: none;
  border: 1px solid #eee;
}

/* Projects
-------------------------------------------------- */

.project {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.project h2 a {
  border-bottom: 0;
}
.project img {
  border: 1px solid rgba(0,0,0,.1);
  border-radius: .25rem;
}

/* Misc
-------------------------------------------------- */

.right {
  float: right;
}
.left {
  float: left;
}
.muted {
  color: #777;
}

.gist-highlight {
  max-height: 20rem;
  overflow-y: scroll;
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.embed-responsive-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}
.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}
.embed-responsive-4by3 {
  padding-bottom: 75%;
}

small {
  color: #ccc;
  padding: 0 0.5em;
}

/* Talk
-------------------------------------------------- */

.talk-title a {
  border-bottom: 0px;
}

/* Archive
-------------------------------------------------- */

.archive {
  margin-bottom: 3rem;
}

.archive-item {
  margin-bottom: 1rem;
  overflow: auto; /* clearfix */
}

.archive-item + .archive-year {
  margin-top: 2rem;
}

.archive-date {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 34rem) {
  .archive-item {
    margin-bottom: .5rem;
  }
  .archive-date {
    float: right;
    margin-left: 1rem;
  }
}

.hll { background-color: #ffffcc }
.c { color: #999988; font-style: italic } /* Comment */
.err { color: #a61717; background-color: #e3d2d2 } /* Error */
.k { color: #000000; font-weight: bold } /* Keyword */
.o { color: #000000; font-weight: bold } /* Operator */
.cm { color: #999988; font-style: italic } /* Comment.Multiline */
.cp { color: #999999; font-weight: bold; font-style: italic } /* Comment.Preproc */
.c1 { color: #999988; font-style: italic } /* Comment.Single */
.cs { color: #999999; font-weight: bold; font-style: italic } /* Comment.Special */
.gd { color: #000000; background-color: #ffdddd } /* Generic.Deleted */
.ge { color: #000000; font-style: italic } /* Generic.Emph */
.gr { color: #aa0000 } /* Generic.Error */
.gh { color: #999999 } /* Generic.Heading */
.gi { color: #000000; background-color: #ddffdd } /* Generic.Inserted */
.go { color: #888888 } /* Generic.Output */
.gp { color: #555555 } /* Generic.Prompt */
.gs { font-weight: bold } /* Generic.Strong */
.gu { color: #aaaaaa } /* Generic.Subheading */
.gt { color: #aa0000 } /* Generic.Traceback */
.kc { color: #000000; font-weight: bold } /* Keyword.Constant */
.kd { color: #000000; font-weight: bold } /* Keyword.Declaration */
.kn { color: #000000; font-weight: bold } /* Keyword.Namespace */
.kp { color: #000000; font-weight: bold } /* Keyword.Pseudo */
.kr { color: #000000; font-weight: bold } /* Keyword.Reserved */
.kt { color: #445588; font-weight: bold } /* Keyword.Type */
.m { color: #009999 } /* Literal.Number */
.s { color: #d01040 } /* Literal.String */
.na { color: #008080 } /* Name.Attribute */
.nb { color: #0086B3 } /* Name.Builtin */
.nc { color: #445588; font-weight: bold } /* Name.Class */
.no { color: #008080 } /* Name.Constant */
.nd { color: #3c5d5d; font-weight: bold } /* Name.Decorator */
.ni { color: #800080 } /* Name.Entity */
.ne { color: #990000; font-weight: bold } /* Name.Exception */
.nf { color: #990000; font-weight: bold } /* Name.Function */
.nl { color: #990000; font-weight: bold } /* Name.Label */
.nn { color: #555555 } /* Name.Namespace */
.nt { color: #000080 } /* Name.Tag */
.nv { color: #008080 } /* Name.Variable */
.ow { color: #000000; font-weight: bold } /* Operator.Word */
.w { color: #bbbbbb } /* Text.Whitespace */
.mf { color: #009999 } /* Literal.Number.Float */
.mh { color: #009999 } /* Literal.Number.Hex */
.mi { color: #009999 } /* Literal.Number.Integer */
.mo { color: #009999 } /* Literal.Number.Oct */
.sb { color: #d01040 } /* Literal.String.Backtick */
.sc { color: #d01040 } /* Literal.String.Char */
.sd { color: #d01040 } /* Literal.String.Doc */
.s2 { color: #d01040 } /* Literal.String.Double */
.se { color: #d01040 } /* Literal.String.Escape */
.sh { color: #d01040 } /* Literal.String.Heredoc */
.si { color: #d01040 } /* Literal.String.Interpol */
.sx { color: #d01040 } /* Literal.String.Other */
.sr { color: #009926 } /* Literal.String.Regex */
.s1 { color: #d01040 } /* Literal.String.Single */
.ss { color: #990073 } /* Literal.String.Symbol */
.bp { color: #999999 } /* Name.Builtin.Pseudo */
.vc { color: #008080 } /* Name.Variable.Class */
.vg { color: #008080 } /* Name.Variable.Global */
.vi { color: #008080 } /* Name.Variable.Instance */
.il { color: #009999 } /* Literal.Number.Integer.Long */

.css .o,
.css .o + .nt,
.css .nt + .nt { color: #999; }

/**
  * Store the link icon as a base64 embedded icon font.
  */
@font-face {
   font-family: 'anchorjs-link';
   src: url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMg6v8yoAAAC8AAAAYGNtYXDL8RqdAAABHAAAADxnYXNwAAAAEAAAAVgAAAAIZ2x5Zkm2oNUAAAFgAAABWGhlYWQAHd4cAAACuAAAADZoaGVhB3sECwAAAvAAAAAkaG10eAYAAEcAAAMUAAAADGxvY2EACgCsAAADIAAAAAhtYXhwAAYAcAAAAygAAAAgbmFtZUQXtNYAAANIAAABOXBvc3QAAwAAAAAEhAAAACAAAwQAAZAABQAAApkCzAAAAI8CmQLMAAAB6wAzAQkAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAACDmAAPA/8D/wAPAAEAAAAAAAAAAAAAAAAAAAAAgAAAAAAACAAAAAwAAABQAAwABAAAAFAAEACgAAAAGAAQAAQACACDmAP//AAAAIOYA////4RoCAAEAAAAAAAAAAQAB//8ADwABAAAAAAAAAAAAAgAANzkBAAAAAAIARwAHA7kDeQA2AG0AAAEnLgEiBg8BDgEUFh8BHgMXNy4DLwEuATQ2PwE+ATIWHwEeARQGDwEeAxU3PgE0JicBLgMnBx4DHwEeARQGDwEOASImLwEuATQ2PwEuAzUHDgEUFh8BHgEyNj8BPgE0Ji8BA7kEI1ldWiPaIyQkIwQDBgYGBFAEBwYHAwQTExMT2xMwMjETBBMTExNjBwkGA5gkIyMk/r4DBgYGBFAEBwYHAwQTExMT2xMwMjETBBMTExNjBwkGA5gkIyMkBCNZXVoj2iMkJCMEA3UEJCMjJNojWV1aIwQDBgUFA1ACBQUFAwQUMDIxE9oTExMTBBMxMjATYxAhISIRmSNaXVkj/sYDBgUFA1ACBQUFAwQUMDIxE9oTExMTBBMxMjATYxAhISIRmSNaXVkjBCQjIyTaI1ldWiMEAAEAAAABAABR/4xQXw889QALBAAAAAAAzqNM0wAAAADOo0zTAAAAAAO5A3kAAAAIAAIAAAAAAAAAAQAAA8D/wAAABAAAAABHA7kAAQAAAAAAAAAAAAAAAAAAAAMAAAAAAgAAAAQAAEcAAAAAAAoArAABAAAAAwBuAAIAAAAAAAIAAAAAAAAAAAAAAAAAAAAAAAAADgCuAAEAAAAAAAEADgAAAAEAAAAAAAIADgBHAAEAAAAAAAMADgAkAAEAAAAAAAQADgBVAAEAAAAAAAUAFgAOAAEAAAAAAAYABwAyAAEAAAAAAAoAKABjAAMAAQQJAAEADgAAAAMAAQQJAAIADgBHAAMAAQQJAAMADgAkAAMAAQQJAAQADgBVAAMAAQQJAAUAFgAOAAMAAQQJAAYADgA5AAMAAQQJAAoAKABjAGkAYwBvAG0AbwBvAG4AVgBlAHIAcwBpAG8AbgAgADAALgAwAGkAYwBvAG0AbwBvAG5pY29tb29uAGkAYwBvAG0AbwBvAG4AUgBlAGcAdQBsAGEAcgBpAGMAbwBtAG8AbwBuAEcAZQBuAGUAcgBhAHQAZQBkACAAYgB5ACAASQBjAG8ATQBvAG8AbgAAAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA) format('truetype'),
      url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAATwAAsAAAAABKQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABPUy8yAAABCAAAAGAAAABgDq/zKmNtYXAAAAFoAAAAPAAAADzL8RqdZ2FzcAAAAaQAAAAIAAAACAAAABBnbHlmAAABrAAAAVgAAAFYSbag1WhlYWQAAAMEAAAANgAAADYAHd4caGhlYQAAAzwAAAAkAAAAJAd7BAtobXR4AAADYAAAAAwAAAAMBgAAR2xvY2EAAANsAAAACAAAAAgACgCsbWF4cAAAA3QAAAAgAAAAIAAGAHBuYW1lAAADlAAAATkAAAE5RBe01nBvc3QAAATQAAAAIAAAACAAAwAAAAMEAAGQAAUAAAKZAswAAACPApkCzAAAAesAMwEJAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAAg5gADwP/A/8ADwABAAAAAAAAAAAAAAAAAAAAAIAAAAAAAAgAAAAMAAAAUAAMAAQAAABQABAAoAAAABgAEAAEAAgAg5gD//wAAACDmAP///+EaAgABAAAAAAAAAAEAAf//AA8AAQAAAAAAAAAAAAIAADc5AQAAAAACAEcABwO5A3kANgBtAAABJy4BIgYPAQ4BFBYfAR4DFzcuAy8BLgE0Nj8BPgEyFh8BHgEUBg8BHgMVNz4BNCYnAS4DJwceAx8BHgEUBg8BDgEiJi8BLgE0Nj8BLgM1Bw4BFBYfAR4BMjY/AT4BNCYvAQO5BCNZXVoj2iMkJCMEAwYGBgRQBAcGBwMEExMTE9sTMDIxEwQTExMTYwcJBgOYJCMjJP6+AwYGBgRQBAcGBwMEExMTE9sTMDIxEwQTExMTYwcJBgOYJCMjJAQjWV1aI9ojJCQjBAN1BCQjIyTaI1ldWiMEAwYFBQNQAgUFBQMEFDAyMRPaExMTEwQTMTIwE2MQISEiEZkjWl1ZI/7GAwYFBQNQAgUFBQMEFDAyMRPaExMTEwQTMTIwE2MQISEiEZkjWl1ZIwQkIyMk2iNZXVojBAABAAAAAQAAUf+MUF8PPPUACwQAAAAAAM6jTNMAAAAAzqNM0wAAAAADuQN5AAAACAACAAAAAAAAAAEAAAPA/8AAAAQAAAAARwO5AAEAAAAAAAAAAAAAAAAAAAADAAAAAAIAAAAEAABHAAAAAAAKAKwAAQAAAAMAbgACAAAAAAACAAAAAAAAAAAAAAAAAAAAAAAAAA4ArgABAAAAAAABAA4AAAABAAAAAAACAA4ARwABAAAAAAADAA4AJAABAAAAAAAEAA4AVQABAAAAAAAFABYADgABAAAAAAAGAAcAMgABAAAAAAAKACgAYwADAAEECQABAA4AAAADAAEECQACAA4ARwADAAEECQADAA4AJAADAAEECQAEAA4AVQADAAEECQAFABYADgADAAEECQAGAA4AOQADAAEECQAKACgAYwBpAGMAbwBtAG8AbwBuAFYAZQByAHMAaQBvAG4AIAAwAC4AMABpAGMAbwBtAG8AbwBuaWNvbW9vbgBpAGMAbwBtAG8AbwBuAFIAZQBnAHUAbABhAHIAaQBjAG8AbQBvAG8AbgBHAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4AAAAAAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA==) format('woff');
   font-weight: normal;
   font-style: normal;
 }

.icon-link {
  font-family: 'anchorjs-link';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Icon Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-link {
  font-size: 60%;
  vertical-align: .1em;
}
.icon-link:before {
  content: "\e600";
}

/**
 * Link placement and hover behavior.
 */

.anchor-link {
  float: left;
  width: 1em;
  height: 1em;
  margin-left: -1.2em;
  opacity: 0;
  text-align: center;
}
.post-body .anchor-link {
  border-bottom: 0;
}
*:hover > .anchor-link,
.anchor-link:focus  {
  opacity: 1;
  -webkit-transition: color .16s linear;
  -moz-transition: color .16s linear;
  -o-transition: color .16s linear;
  transition: color .16s linear;
}

/* InstantClick
-------------------------------------------------- */

#instantclick-bar {
  background: rgba(159,159,159,0.8);
}

/* Profile Image
-------------------------------------------------- */

.about {
  position: relative;
  padding-bottom: 1.5rem;
}

.about .img-profile {
  height: auto;
  max-height: inherit;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0,0,0,0.3);
  border: 3px solid #fff;
  width: 25%;
}

@media (max-width: 48em) {
  .about .img-profile {
    width: 45%;
  }
}

.avatar {
   float: left;
   height: auto;
   max-height: inherit;
   position: relative;
   display: block;
   margin-left: 1em;
   margin-right: 1em;
   border-radius: 4.854em;
   box-shadow: 0 1px 1px rgba(0,0,0,0.3);
   border: 1px solid #fff;
   width: 75px;
}

@media (max-width: 34em) {
  .avatar {
    float: none;
    width: 100px;
    margin: auto;
    margin-bottom: 1rem;
  }
}

/* github-cards
-------------------------------------------------- */

.github-cards {
  text-align: center;
}

/* zoom.js
-------------------------------------------------- */

img[data-action="zoom"] {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
}
.zoom-img,
.zoom-img-wrap {
  position: relative;
  z-index: 666;
  -webkit-transition: all 300ms;
       -o-transition: all 300ms;
          transition: all 300ms;
  border:0 !important;
  box-shadow: none !important;
}
img.zoom-img {
  cursor: pointer;
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
}
.zoom-overlay {
  z-index: 420;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  filter: "alpha(opacity=0)";
  opacity: 0;
  -webkit-transition:      opacity 300ms;
       -o-transition:      opacity 300ms;
          transition:      opacity 300ms;
}
.zoom-overlay-open .zoom-overlay {
  filter: "alpha(opacity=100)";
  opacity: 1;
}
.zoom-overlay-open,
.zoom-overlay-transitioning {
  cursor: default;
}

/* Image Caption
-------------------------------------------------- */

.image-caption {
  font-family: "Lucida Grande","Lucida Sans Unicode","Lucida Sans",Geneva,Verdana,sans-serif;
  letter-spacing: -.02em;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  line-height: 1.2;
  color: rgba(0,0,0,.6);
  letter-spacing: 0;
  text-align: center;
}

/* Twitter Icon
-------------------------------------------------- */

.icon-twitter {
 font-family: 'twitter-icon';
 font-style: normal;
 font-weight: normal;
 font-variant: normal;
 text-transform: none;
 line-height: 1;

 /* Better Icon Rendering */
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}
.icon-twitter {
 vertical-align: .005em;
}
.icon-twitter:before {
 content: "\e80a";
}

/* Dark Mode Icon
-------------------------------------------------- */

.icon-darkmode {
 font-family: 'darmode-icon';
 font-style: normal;
 font-weight: normal;
 font-variant: normal;
 text-transform: none;
 line-height: 1;

 /* Better Icon Rendering */
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}
.icon-darkmode {
  vertical-align: .005em;
}
.icon-darkmode:before {
 content: "\f042";
}
